import UnicefLogoImage from '../assets/stock/UNICEF-logo.png';
import UNFPALogoImage from '../assets/stock/UNFPA_logo.png';
import LogoTanzania from '../assets/stock/moh-132678-tz.jpg';
import KenyaLogo from "../assets/stock/logo_gok.png";
// import UnaitasLogo from "../assets/stock/Unaitas_Logo.webp";
// import RwandaLogo from "../assets/stock/Rwanda-Biomedical-Center_Logo.png";
import SautiLogo from '../assets/stock/Sauti_Logo.png';
import ChildlineLogo from '../assets/stock/childline_kenya_logo.jpeg';
// import Eprocurement from "../assets/stock/Rwanda_Eprocurement.jpeg";
// import JabaliSchoolLogo from "../assets/stock/Jabali_Christian_School_logo.png";
export const PartnersData = [
  {
    id: 0,
    title: 'Unicef For Every Child',
    description: 'Built the child helpline Case Management and Call Center for Childline Kenya. The work done was, Requirement,Gathering, wireframing, SoftwareDevelopment, Deployment, user testing, Training, and User Support.Project Timelines was 8 Months',
    logo: UnicefLogoImage,
    Link: 'https://www.unicef.org/child-alert/severe-wasting',
    contactEmail: 'info@example.com',
    contactPhone: '+254 700 000 000',
  },
  {
    id: 1,
    title: 'UNFPA',
    description: 'Built the child helpline Case Management and Call Center for Childline Kenya. The work done was, Requirement,Gathering, wireframing, SoftwareDevelopment, Deployment, user testing, Training, and User Support.Project Timelines was 8 Months',
    logo: UNFPALogoImage,
    Link: 'https://www.unfpa.org/',
    contactEmail: 'unfpa@unfpa.org',
    contactPhone: '+254 713860954',
  },
  {
    id: 2,
    title: 'Ministry of Community Development, Gender, Elderly and Children (MCDGEC-Tanzania)',
    description: '  ',
    logo: LogoTanzania,
    Link: 'https://www.turkanabasin.org/',
    contactEmail: 'turkanabasin@turkanabasin.org',
    contactPhone: '+254 713860954',
  },
  {
    id: 3,
    title: 'Sauti 116 Helpline',
    description: '',
    logo: SautiLogo,
    Link: 'https://sauti.mglsd.go.ug/sauti/',
    contactEmail: 'sauti@sauti.mglsd.go.ug',
    contactPhone: '+256 700 000 000',
  },
  {
    id: 4,
    title: "Ministry of Gender and Social Development",
    description: "",
    logo: KenyaLogo,
    Link: "https://towersacco.co.ke/",
    contactEmail: "towersacco@towersacco.co.ke",
    contactPhone: "+256 700 000 000",
  },
  // {
  //   id: 5,
  //   title: " Unaitas",
  //   description: "",
  //   logo: UnaitasLogo,
  //   Link: "https://unaitas.com/",
  // },
  // {
  //   id: 6,
  //   title: "Rwanda Biomedical Center",
  //   description: "",
  //   logo: RwandaLogo,
  //   Link: "https://www.rbc.gov.rw/index.php?id=188",
  // },
  {
    id: 7,
    title: 'Childline Kenya',
    description: '',
    logo: ChildlineLogo,
    Link: 'https://childlinekenya.co.ke/',
  },
  // {
  //   id: 8,
  //   title: "Rwanda Online Eprocurement System",
  //   description: "",
  //   logo: Eprocurement,
  //   Link: "https://www.umucyo.gov.rw/",
  // },
  // {
  //   id: 9,
  //   title: "Jabali Christian School",
  //   description: "",
  //   logo: JabaliSchoolLogo,
  //   Link: "https://jabali.ac.ke/index.php/en/",
  // },
];
