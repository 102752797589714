<template>
  <LayoutFile />>
</template>

<script>
  import LayoutFile from './components/LayoutFile.vue';

  export default {
    components: {
      LayoutFile,
    }
  }


</script>

<style>


  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;



  }


</style>
